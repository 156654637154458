import React from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { grey } from '@material-ui/core/colors';
import Divider from '@material-ui/core/Divider';

import Footer from '../../Components/Layout/Footer';
import FooterUna from './Footer';
import { Images } from '../../Themes';
import {
  ImageCardsContainer,
  AccordionContainer,
  BulletPointContainer,
  PartnerCard,
  HeaderCard,
} from '../../Components/Block';
import LayoutStyles from '../../Components/Layout/Styles/LayoutStyles';
import SearchBar from './SearchBar';

type Props = {
  classes: Object,
};

const styles = theme => ({
  container: {
    backgroundColor: 'white',
    justifyContent: 'center',
  },
  layout: {
    ...LayoutStyles.fullWidthLayout,

    margin: '2rem 3rem 3rem',
  },
  heroLayout: {
    // Make the picture goes under the navbar
    ...LayoutStyles.navbarPaddingTop,

    width: '100%',
    backgroundImage: `url(${Images.una.jumbo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    padding: '10rem 0',
  },
  nobullets: {
    listStyleType: 'none',
  },
  heroTitle: {
    color: 'white',
    textShadow: '0.5px 0.5px #3c3c3c73',
  },
  heroTitleLocation: {
    marginLeft: '20%',
  },
  heroForm: {
    paddingTop: theme.spacing(3),
  },
  subHeroLayout: {
    width: '100%',
    height: '250px',
    margin: '3rem 0 3rem',
    backgroundImage: `url(${Images.una.sub_jumbo})`,
    backgroundSize: 'cover',
  },
  benefit: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Images.una.benefit})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    borderRadius: 5,
    boxShadow: theme.shadows['1'],
  },
  service: {
    width: '100%',
    height: '700px',
    backgroundImage: `url(${Images.una.service})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
    borderRadius: 5,
    boxShadow: theme.shadows['1'],
  },
  headingContainer: {
    width: '100%',
    margin: '3rem 0 3rem',
  },
  ctaProLayout: {
    paddingBottom: '60px',
    paddingTop: '60px',
    backgroundColor: theme.una.primary.thin,
  },
  ctaProSecondaryTitle: {
    color: theme.una.primary.main,
  },
  ctaProContent: {
    ...LayoutStyles.fullWidthLayout,
  },
  partnerLayout: {
    padding: theme.spacing(10),
  },
  pushDownSections: {
    marginTop: -theme.spacing(7),
    [`${theme.breakpoints.up('xl')}`]: {
      margin: '-56px 400px 30px 400px',
    },
  },
  xlSection: {
    [`${theme.breakpoints.up('xl')}`]: {
      width: '100%',
    },
  },
  widthSection: {
    [`${theme.breakpoints.up('xl')}`]: {
      width: '1200px',
      margin: 'auto',
    },
  },
  footerLayout: {
    backgroundColor: '#EEEEEE',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(0),
  },
  formControl: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  link: {
    textDecorationLine: 'none',
    color: grey[600],
  },
  footerText: {
    color: grey[600],
  },
});

function Homepage({ classes }: Props) {
  const { t } = useTranslation();

  return (
    <>
      {/* Use default meta from i18n (see App.jsx for more information):
        - ${Routes.currentNamespace()}.SEO.HOMEPAGE.TITLE
        - ${Routes.currentNamespace()}.SEO.HOMEPAGE.DESCRIPTION
      */}
      <Grid container className={classes.container}>
        {/* HERO CTA SECTION */}
        <Grid item className={classes.heroLayout}>
          <Grid item align="center" className={classNames(classes.layout)}>
            <Typography variant="h1" align="center" className={classes.heroTitle} gutterBottom>
              {t('UNA.HOMEPAGE.TITLE')}
            </Typography>
            <Typography
              variant="subtitle1"
              component="h2"
              align="center"
              className={classes.heroTitle}
              paragraph
            >
              {t('UNA.HOMEPAGE.SUBTITLE')}
            </Typography>
            <SearchBar id="search-2" />
            <Typography
              variant="subtitle1"
              align="center"
              className={classNames(classes.heroTitleLocation, classes.heroTitle)}
              paragraph
            >
              {t('UNA.HOMEPAGE.SUBTITLE.LOCATION')}
            </Typography>
          </Grid>
        </Grid>

        {/* BENEFIT SECTION */}
        <Grid
          spacing={2}
          container
          className={classNames(classes.layout, classes.pushDownSections)}
        >
          <Grid item md={4} xs={12}>
            <HeaderCard
              img={Images.una.benefit_card_1}
              title="UNA.HOMEPAGE.BENEFIT.VALUE_1.TITLE"
              content="UNA.HOMEPAGE.BENEFIT.VALUE_1.CONTENT"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <HeaderCard
              img={Images.una.benefit_card_2}
              title="UNA.HOMEPAGE.BENEFIT.VALUE_2.TITLE"
              content="UNA.HOMEPAGE.BENEFIT.VALUE_2.CONTENT"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <HeaderCard
              img={Images.una.benefit_card_3}
              title="UNA.HOMEPAGE.BENEFIT.VALUE_3.TITLE"
              content="UNA.HOMEPAGE.BENEFIT.VALUE_3.CONTENT"
            />
          </Grid>
        </Grid>

        {/* WHO AR WE SECTION */}
        <Grid container className={classNames(classes.layout, classes.xlSection)}>
          <Grid className={classes.widthSection}>
            <Grid item className={classes.headingContainer}>
              <Grid item xs={12}>
                <Typography variant="h2" align="center" gutterBottom>
                  {t('UNA.HOMEPAGE.WHY.TITLE')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                  {t('UNA.HOMEPAGE.WHY.SUBTITLE')}
                </Typography>
              </Grid>
            </Grid>
            <Grid spacing={2} container>
              <Grid item md={4} xs={12}>
                <div className={classes.benefit} />
              </Grid>
              <Grid item md={8} xs={12}>
                <BulletPointContainer
                  benefits={[
                    {
                      bullet: '3000',
                      title: 'UNA.HOMEPAGE.WHY.VALUE_1.TITLE',
                      content: 'UNA.HOMEPAGE.WHY.VALUE_1.CONTENT',
                    },
                    {
                      bullet: '95%',
                      title: 'UNA.HOMEPAGE.WHY.VALUE_2.TITLE',
                      content: 'UNA.HOMEPAGE.WHY.VALUE_2.CONTENT',
                    },
                    {
                      bullet: '24H',
                      title: 'UNA.HOMEPAGE.WHY.VALUE_3.TITLE',
                      content: 'UNA.HOMEPAGE.WHY.VALUE_3.CONTENT',
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* PRO DISCOVER PORTRAIT SECTION */}
        <Grid container className={classes.layout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" gutterBottom>
                {t('UNA.HOMEPAGE.DISCOVER.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('UNA.HOMEPAGE.DISCOVER.SUBTITLE')}
              </Typography>
            </Grid>
          </Grid>
          <Grid spacing={4} container justify="center">
            <ImageCardsContainer
              benefits={[
                {
                  title: 'UNA.HOMEPAGE.DISCOVER.PORTRAIT_1.TITLE',
                  image: Images.una.portrait_1,
                  hovered: true,
                  content: 'UNA.HOMEPAGE.DISCOVER.PORTRAIT_1.CONTENT',
                },
                {
                  title: 'UNA.HOMEPAGE.DISCOVER.PORTRAIT_2.TITLE',
                  image: Images.una.portrait_2,
                  hovered: true,
                  content: 'UNA.HOMEPAGE.DISCOVER.PORTRAIT_2.CONTENT',
                },
                {
                  title: 'UNA.HOMEPAGE.DISCOVER.PORTRAIT_3.TITLE',
                  image: Images.una.portrait_3,
                  hovered: true,
                  content: 'UNA.HOMEPAGE.DISCOVER.PORTRAIT_3.CONTENT',
                },
                {
                  title: 'UNA.HOMEPAGE.DISCOVER.PORTRAIT_4.TITLE',
                  image: Images.una.portrait_4,
                  hovered: true,
                  content: 'UNA.HOMEPAGE.DISCOVER.PORTRAIT_4.CONTENT',
                },
              ]}
            />
          </Grid>
        </Grid>

        {/* SEARCH CTA SECTION */}
        <Grid item className={classes.subHeroLayout}>
          <Grid item className={classes.layout}>
            <Typography variant="h2" align="center" className={classes.heroTitle} gutterBottom>
              {t('UNA.HOMEPAGE.CTA.TITLE')}
            </Typography>
            <SearchBar id="search-1" />
            <Typography
              variant="subtitle1"
              component="h3"
              align="center"
              className={classNames(classes.heroTitleLocation, classes.heroTitle)}
              paragraph
            >
              {t('UNA.HOMEPAGE.SUBTITLE.LOCATION')}
            </Typography>
          </Grid>
        </Grid>

        {/* SERVICE ACCORDION SECTION */}
        <AccordionContainer
          title="UNA.HOMEPAGE.SERVICE.TITLE"
          subTitle="UNA.HOMEPAGE.SERVICE.SUBTITLE"
          backgroundImage={Images.una.service}
          imgHeight="672px"
          benefits={[
            {
              title: 'UNA.HOMEPAGE.SERVICE.VALUE_1.TITLE',
              content: 'UNA.HOMEPAGE.SERVICE.VALUE_1.CONTENT',
              withIndex: true,
            },
            {
              title: 'UNA.HOMEPAGE.SERVICE.VALUE_2.TITLE',
              content: 'UNA.HOMEPAGE.SERVICE.VALUE_2.CONTENT',
              withIndex: true,
            },
            {
              title: 'UNA.HOMEPAGE.SERVICE.VALUE_3.TITLE',
              content: 'UNA.HOMEPAGE.SERVICE.VALUE_3.CONTENT',
              withIndex: true,
            },
            {
              title: 'UNA.HOMEPAGE.SERVICE.VALUE_4.TITLE',
              content: 'UNA.HOMEPAGE.SERVICE.VALUE_4.CONTENT',
              withIndex: true,
            },
            {
              title: 'UNA.HOMEPAGE.SERVICE.VALUE_5.TITLE',
              content: 'UNA.HOMEPAGE.SERVICE.VALUE_5.CONTENT',
              withIndex: true,
            },
            {
              title: 'UNA.HOMEPAGE.SERVICE.VALUE_6.TITLE',
              content: 'UNA.HOMEPAGE.SERVICE.VALUE_6.CONTENT',
              withIndex: true,
            },
            {
              title: 'UNA.HOMEPAGE.SERVICE.VALUE_7.TITLE',
              content: 'UNA.HOMEPAGE.SERVICE.VALUE_7.CONTENT',
              withIndex: true,
            },
            {
              title: 'UNA.HOMEPAGE.SERVICE.VALUE_8.TITLE',
              content: 'UNA.HOMEPAGE.SERVICE.VALUE_8.CONTENT',
              withIndex: true,
            },
          ]}
        />

        {/* PRO CTA SECTION */}
        <Grid container className={classes.ctaProLayout}>
          <Grid item align="center" className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" align="center" gutterBottom>
                {t('UNA.PRO.TITLE')}
              </Typography>

              <Typography
                variant="subtitle1"
                component="h3"
                className={classes.ctaProSecondaryTitle}
                align="center"
                gutterBottom
              >
                {t('UNA.PRO.SUBTITLE')}
              </Typography>

              <Typography
                variant="subtitle1"
                className={classes.ctaProContent}
                align="center"
                paragraph
              >
                {t('UNA.PRO.SUBTITLE.BODY')}
              </Typography>
              <Button to="/pro" component={Link} variant="outlined" color="inherit">
                {t('UNA.PRO.ROOM')}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* PARTNER SECTION */}
        <Grid container className={classes.partnerLayout}>
          <Grid item className={classes.headingContainer}>
            <Grid item xs={12}>
              <Typography variant="h3" align="center" gutterBottom>
                {t('UNA.HOMEPAGE.PARTNER.TITLE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" component="h3" align="center" gutterBottom>
                {t('UNA.HOMEPAGE.PARTNER.SUBTITLE')}
              </Typography>
            </Grid>
            <Grid container align="center">
              <Grid item md={4} xs={12}>
                <PartnerCard
                  img={Images.una.partner1}
                  title="UNA.HOMEPAGE.PARTNER.PARTNER_UNA.TITLE"
                  content="UNA.HOMEPAGE.PARTNER.PARTNER_UNA.CONTENT"
                />
                <Button color="inherit" href="http://www.una.fr">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
              <Grid item md={4} xs={12}>
                <PartnerCard
                  img={Images.wello.partner}
                  title="WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.TITLE"
                  content="WELLO.HOMEPAGE.PARTNER.PARTNER_WELLO.CONTENT"
                />
                <Button color="inherit" href="https://www.wello.fr">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
              <Grid item md={4} xs={12}>
                <PartnerCard
                  img={Images.una.partner2}
                  title="UNA.HOMEPAGE.PARTNER.PARTNER_REGION.TITLE"
                  content="UNA.HOMEPAGE.PARTNER.PARTNER_REGION.CONTENT"
                />
                <Button color="inherit" href="http://www.paysdelaloire.fr">
                  {t('KNOW_MORE')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* FOOTER SECTION */}
      <FooterUna />
      <Divider />
      <Footer />
    </>
  );
}

export default withStyles(styles)(withRouter(Homepage));
