import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import values from 'lodash/values';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';

import ToasterActions from '../../Redux/ToasterRedux';
import AutoCompleteCity from '../../Components/Shared/AutoCompleteCity';
import { DEPARTMENT_CODES } from './UnaHelper';

type Props = {
  id: String,
  city: String,
  service: String,
  history: Object,
  classes: Object,
  className: Object,
};

const styles = theme => ({
  heroForm: {
    marginTop: '1.5em',
  },
  submitButton: {
    // marginTop: theme.spacing(2),
  },
  formControl: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  cityError: {
    border: '1px solid #F44335',
  },
});

function SearchBar({
  id,
  city: cityName,
  service: serviceSlug,
  className,
  classes,
  history,
}: Props) {
  const [service, setService] = React.useState(serviceSlug || '');
  const [serviceError, setServiceError] = React.useState(null);
  const [city, setCity] = React.useState(cityName);
  const [cityError, setCityError] = React.useState(null);

  const handleSubmit = () => {
    // const leagueId = 1;
    if (_.isEmpty(city)) {
      setCityError(true);
    }
    if (!_.isEmpty(city)) {
      if (_.isEmpty(service)) {
        setService('aide-au-menage');
        history.push(`/cities/${city}/services/aide-au-menage`);
      }
      if (!_.isEmpty(service)) {
        history.push(`/cities/${city}/services/${service}`);
      }
    }
  };

  return (
    <Grid container justify="center" spacing={2} className={className || classes.heroForm}>
      <Grid item sm={1} xs={12} />
      <Grid item sm={4} xs={12}>
        <Select
          align="left"
          fullWidth
          displayEmpty
          value={service}
          className={classes.formControl}
          onChange={e => {
            setService(e.target.value);
            setServiceError(null);
          }}
          input={
            <OutlinedInput labelWidth={0} name="activity" id={id || 'search-activity-select'} />
          }
          variant="outlined"
          error={serviceError !== null}
        >
          <MenuItem value="" disabled>
            Sélectionner le service
          </MenuItem>
          <MenuItem value="aide-autonomie">Aide à l&apos;autonomie</MenuItem>
          <MenuItem value="accompagnement-sortie">Accompagnement sortie</MenuItem>
          <MenuItem value="aide-au-menage">Aide au ménage</MenuItem>
        </Select>
      </Grid>
      <Grid item sm={4} xs={12}>
        <AutoCompleteCity
          value={city}
          fullWidth
          classes={cityError !== null ? classes.cityError : {}}
          className={cityError !== null ? classes.cityError : {}}
          allowedZipCodes={values(DEPARTMENT_CODES)}
          onSuggestionSelected={value => {
            setCity(value);
            setCityError(null);
          }}
          error={cityError !== null}
        />
      </Grid>
      <Grid item sm={1} xs={12}>
        <Fab color="primary" className={classes.submitButton} onClick={handleSubmit}>
          GO
        </Fab>
      </Grid>
      <Grid item />
    </Grid>
  );
}

const mapDispatchToProps = dispatch => ({
  showToaster: message => dispatch(ToasterActions.showToaster(message, 'error', 5000)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(withRouter(SearchBar)));
